<template>
    <section>
        <bread-crumb :navigationArray="navigationLink"/>
      <div class="two-rows-wrapper">
        <div class="white-container">
          <h5>{{ $store.getters.GET_LANG ? 'Противодействие коррупции' : 'Anti-corruption' }}</h5>
          <tricolor-line />
          <div class="block-structure" v-for="(item, num) of $store.getters.getCorruptionSection" :key="'id_'+num" :id="'corruption'+item.title">
            <p class="title_structure">{{item.title}}</p>
            <ul>
              <li v-for="(index, i) of groupsArr(item.groups)" :key="i">
                <router-link v-if="index.link === null" :to="`/anti_corruption_measures/${item.id}?title=${index.title}`">
                  {{index.title}} <span>({{ index.count_files }})</span>
                </router-link>
                <router-link v-else-if="index.link === '/staff_chamber'" :to="index.link">
                  {{index.title}}<span class="website_img"><span></span></span>
                </router-link>
                <a v-else :href="index.link" target="_blank">
                  {{index.title}}<span class="website_img"><span></span></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <aside class="two-rows-navigation">
          <h2>{{ $store.getters.GET_LANG ? 'Навигация по странице' : 'Page navigation' }}</h2>
          <nav>
            <a v-for="(nav, i, index) in $store.getters.getCorruptionSection" :key="index" :href="`#corruption${nav.title}`">{{ nav.title }}</a>
            <!--          <router-link v-for="(nav, i, index) in anchorNavigation" :key="index" :to="{ path: '/', hash: `nav_${index}` }">{{ nav }}</router-link>-->
          </nav>
        </aside>
      </div>
    </section>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
export default {
  name: 'AntiCorruptionMeasures',
  data () {
    return {
      contentLink: []
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('setCorruptionSection', {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
    },
    groupsArr (data) {
      if (data[0].id > data[data.length - 1].id) {
        data.sort((a, b) => a.id - b.id)
      }
      return data
    }
  },
  mounted () {
    this.getData()
    document.title = 'Противодействие коррупции'
  },
  computed: {
    navigationLink () {
      return [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Противодействие коррупции' : 'Anti-corruption'
        }
      ]
    }
  },
  watch: {
    '$store.getters.GET_LANG' () {
      this.getData()
    }
  },
  components: {
    BreadCrumb,
    TricolorLine
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: 61.44rem;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 1.875rem;
}
.block-structure{ margin-top: 3.125rem; }
#id_0{ margin-top: 1.875rem;}

.title_structure{
    width: 100%;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1F3245;
    opacity: 0.8;
    padding-bottom: 0.5rem;
}

ul > li{
    list-style-type: disc;
    color: #246CB7;
    margin:  0.75rem 0 0 1rem;
}
a{
    font-size: 0.875rem;
    line-height: 1.38rem;
    color: #246CB7;
    margin-top: 0.75rem;
}
.website_img{
    padding-right: 1.56rem;
    position: relative;
}
.website_img span{
    background: url('../../assets/icons/link_anticorrop.svg') center center no-repeat;
    background-size: .813rem;
    padding: .1rem 0.6rem;
    margin-left: .7rem;
}
@media screen and (max-width: 768px){
    .white-container{
        width: auto;
        margin: 0 .75rem;
        padding: 1.86rem 1.25rem;
    }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    ul > li {
        margin: .95rem 0 0 .76rem;
        font-size: .64rem;
    }
    // .website_img:before {
    //     bottom: .2rem;
    //     top: auto;
    // }
}
</style>
